import '../css/main.scss'
import 'lazysizes'
import Sticky from 'sticky-js'
import nav from './components/nav/nav'
import jumpLinks from './components/jumplinks/jumplinks'
import filterGrid from './components/filtergrid/filtergrid'
import hideSidebar from './components/sidebar/sidebar'
import selection from './components/selection/selection'
import animeJs from './vendor/animejs.min'
import onReady from './on-ready'
import SearchAutocomplete from './components/SearchAutocomplete.vue'
import './vendor/tilter'
import * as Sentry from '@sentry/vue'
const picturefill = require('picturefill')

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.loadMode = 1
window.anime = animeJs

const main = async () => {
    const {default: Vue} = await import('vue')
    const isProduction = process.env.NODE_ENV === 'production'

    if (isProduction) {
        Sentry.init({
            Vue,
            dsn: 'https://46bafcb6bb9640eab8f2e4449e9e876d@sentry.yournext.agency/21'
        })
    }

    new Vue({
        el: '#nav-menu',
        components: {
            'search-autocomplete': SearchAutocomplete
        },
        delimiters: ['${', '}'],
        data: {
            menuOpen: false
        },
        methods: {
            prerenderLink: function (e) {
                const head = document.getElementsByTagName('head')[0]
                const refs = head.childNodes
                const ref = refs[refs.length - 1]
                const elements = head.getElementsByTagName('link')
                Array.prototype.forEach.call(elements, function (el) {
                    if (('rel' in el) && (el.rel === 'prerender'))
                        el.parentNode.removeChild(el)
                })
                const prerenderTag = document.createElement('link')
                prerenderTag.rel = 'prerender'
                prerenderTag.href = e.currentTarget.href
                ref.parentNode.insertBefore(prerenderTag, ref)
            },
            toggle: function () {
                this.menuOpen = !this.menuOpen
            }
        }
    })
}

main().then(() => {
    onReady(() => {
        if (!document.body.classList.contains('homepage')) {
            nav()
        }
        picturefill()
        jumpLinks()
        filterGrid()
        hideSidebar()
        selection()
        new Sticky('.js-sticky-element')
        const arr = []
        arr.slice.call(document.querySelectorAll('a.tilter')).forEach(function (el) {
            // eslint-disable-next-line no-undef
            new TiltFx(el)
        })
    })
})
