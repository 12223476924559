import {TimelineLite, Power4} from 'gsap'
import request from 'superagent'
import h from '../helpers/helpers.js'

const html = document.documentElement
const tl = new TimelineLite()
const emailSelection = document.querySelector('.js-email-selection')
const selectionOverlay = document.querySelector('.js-selection-overlay')
const selectionOverlayClose = document.querySelector('.js-selection-overlay-close')
const addMeasurementButton = document.querySelector('.js-add-measurement-button')

const Selection = () => {
    if (selectionOverlay) {
        if (emailSelection)
            emailSelection.addEventListener('click', openOverlay)

        selectionOverlayClose.addEventListener('click', closeOverlay)

        const form = selectionOverlay.querySelector('form')
        form.addEventListener('submit', handleSubmit)
    }

    const toggleSelectionButtons = document.querySelectorAll('.js-toggle-selection')

    if (toggleSelectionButtons) {
        Array.prototype.forEach.call(toggleSelectionButtons, (button) => {
            // Handlers
            button.addEventListener('click', (e) => {
                e.preventDefault()
                toggleButton(button)
            })
        })
    }
    toggleButtonOnLoad()
}

const toggleButtonOnLoad = () => {
    request
        .get('/actions/modelselector/front/get-selection')
        .end((err, res) => {
            if (err) {
                // TODO: error handling
            } else {

                if (res.statusCode == 200) {

                    const selection = JSON.parse(res.text)
                    selection.selection.forEach(function (modelId, index) {
                        const button = document.querySelector('button[data-model-id="' + modelId + '"]')
                        if(button){
                            toggleButtonState(button)
                        }
                    })

                    //if (selection.meta.count) {
                        updateCounter(selection.meta.count)
                    //}
                }
            }
        })
}

const handleSubmit = (event) => {
    event.preventDefault()

    if (event.target) {
        const data = h.serialize(event.target)

        request
            .post('/')
            .set('Content-type', 'application/x-www-form-urlencoded')
            .set('X-Requested-With', 'XMLHttpRequest')
            .send(data)
            .end((err, res) => {
                const message = selectionOverlay.querySelector('.selection-overlay__message')

                if (res.body.success) {
                    message.innerText = res.body.message
                    message.classList.remove('dn')

                    tl.to(selectionOverlay.querySelectorAll('.selection-overlay__item'), 0.4, {
                        opacity: 0,
                        ease: Power4.easeOut
                    })
                        .fromTo(message, 0.4,
                            {x: -40, opacity: 0, ease: Power4.easeOut},
                            {x: 0, opacity: 1, ease: Power4.easeOut})
                        .call(() => {
                            closeOverlay()
                        }, null, null, '+=2')
                }
            })
    }
}

const toggleButtonState = (button) => {
    const textAdd = button.querySelector('.add')
    const textRemove = button.querySelector('.remove')
    const active = button.classList.contains('is-active')

    if (!active) {
        button.classList.add('is-active')
        textRemove.classList.remove('dn')
        textAdd.classList.add('dn')
    } else {
        button.classList.remove('is-active')
        textAdd.classList.remove('dn')
        textRemove.classList.add('dn')
    }
}

const updateCounter = (count) => {
    const counters = document.querySelectorAll('.js-selection-counter')

    Array.prototype.forEach.call(counters, (counter) => {
        counter.textContent = count
    })
}

const toggleButton = (button) => {
    request
        .post('/actions/modelselector/front/toggle-model')
        .send('modelId=' + button.getAttribute('data-model-id'))
        .end((err, res) => {
            if (err) {
                // TODO: error handling
            } else {
                if (res.body.success) {
                    toggleButtonState(button)

                    if (window.location.href.indexOf('selection') > -1) {
                        window.location.reload()
                        button.closest('.model').remove()
                    }

                    if (res.body.count >= 0) {
                        updateCounter(res.body.count)
                    }
                }
            }
        })
}

const openOverlay = () => {
    selectionOverlay.classList.toggle('dn')
    selectionOverlay.querySelector('.selection-overlay__message').classList.add('dn')

    tl.fromTo(selectionOverlay, 0.4,
        {opacity: 0, ease: Power4.easeOut},
        {opacity: 1, ease: Power4.easeOut})
        .staggerFromTo(selectionOverlay.querySelectorAll('.selection-overlay__item'), 0.4,
            {x: '-40px', opacity: 0, ease: Power4.easeOut},
            {x: 0, opacity: 1, ease: Power4.easeOut},
            0.1)
    if (html.classList.contains('has-nav-overlay')) {
        h.stopBodyScrolling(true)
        document.body.style.overflow = 'hidden'
    } else {
        h.stopBodyScrolling(false)
        document.body.style.overflow = 'visible'
    }
}

const closeOverlay = () => {
    tl.to(selectionOverlay.querySelectorAll('.selection-overlay__item'), 0.4, {
        x: '-40px',
        opacity: 0,
        ease: Power4.easeOut
    })
        .to(selectionOverlay, 0.4, {opacity: 0, ease: Power4.easeOut})
        .call(() => {
            selectionOverlay.classList.toggle('dn')

            if (html.classList.contains('has-nav-overlay')) {
                h.stopBodyScrolling(true)
                document.body.style.overflow = 'hidden'
            } else {
                h.stopBodyScrolling(false)
                document.body.style.overflow = 'visible'
            }
        }, null, null, null)
}


export default Selection
