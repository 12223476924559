import Isotope from 'isotope-layout'
import imagesLoaded from 'imagesloaded'

const getHashFilter = () => {
  const matches = location.hash.match(/filter=([^&]+)/i)
  const hashFilter = matches && matches[1]
  return hashFilter && decodeURIComponent(hashFilter)
}

const grid = document.querySelector('.js-grid')
const filtersContainerDesktop = document.querySelector('.js-filters-desktop')
const filtersContainerMobile = document.querySelector('.js-filters-mobile')

let iso = null

const filterGrid = () => {
  if (!grid) return
  if (!filtersContainerDesktop) return
  if (!filtersContainerMobile) return

  const filtersDesktop = filtersContainerDesktop.querySelectorAll('.js-filter')
  const filtersMobile = filtersContainerMobile.querySelectorAll('.js-filter')

  const modelDetailPage = document.querySelector('.js-filters--model-detail')
  if (modelDetailPage) {
    const getDefaultHashFilter = () => {
      window.location.hash = 'filter=.photo'
    }
    getDefaultHashFilter()
  }


  const handleClick = (event) => {
    const filterValue = event.target.getAttribute('data-filter')
    // set filter in hash
    location.hash = `filter=${encodeURIComponent(filterValue)}`
  };

  [].forEach.call(filtersDesktop, (el) => {
    el.addEventListener('click', handleClick)
  });

  [].forEach.call(filtersMobile, (el) => {
    el.addEventListener('click', handleClick)
  })

  let isIsotopeInit = false

  const handleHashChange = () => {
    const hashFilter = getHashFilter()
    if (!isIsotopeInit) {
      iso = new Isotope(grid, {
        itemSelector: '.js-grid-item',
        layoutMode: 'fitRows',
        filter: hashFilter
      })
      isIsotopeInit = true
    } else {
      iso.arrange({ filter: hashFilter })
    }


    // set selected class on button
    if (hashFilter) {
      const mobileActiveFilter = filtersContainerMobile.querySelector('.is-active')
      if (mobileActiveFilter) mobileActiveFilter.classList.remove('is-active')
      const mobileSelectedFilter = filtersContainerMobile.querySelector(`[data-filter="${hashFilter}"]`)
      if (mobileSelectedFilter) mobileSelectedFilter.classList.add('is-active')
      const desktopActiveFilter = filtersContainerDesktop.querySelector('.is-active')
      if (desktopActiveFilter) desktopActiveFilter.classList.remove('is-active')
      const desktopSelectedFilter = filtersContainerDesktop.querySelector(`[data-filter="${hashFilter}"]`)
      if (desktopSelectedFilter) desktopSelectedFilter.classList.add('is-active')
    }
  }

   imagesLoaded(grid).on('progress', () => {
     iso.arrange()
   })

   document.addEventListener('lazybeforeunveil', () => {
     // layout Isotope after each image lazyloads
     iso.layout()
   })

   grid.addEventListener('load', () => {
     iso.layout()
   }, true)

  window.addEventListener('hashchange', handleHashChange)
  // trigger event handler to init Isotope
  handleHashChange()
}

export default filterGrid
