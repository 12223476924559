import { TimelineLite, Power4 } from 'gsap'
import h from '../helpers/helpers.js'

const html = document.documentElement
const navEl = document.querySelector('.js-nav')
const navToggle = document.querySelector('.js-nav-toggle')
const navToggleClose = document.querySelector('.js-nav-toggle-close')
const navItem = document.querySelectorAll('.js-nav-item')
const tl = new TimelineLite()

const toggleNavEls = () => {
  navToggle.classList.toggle('active')
  navToggle.classList.toggle('z-9999')
  navEl.classList.toggle('dn')
  navEl.classList.toggle('flex')
  navEl.classList.toggle('justify-center')
  navEl.classList.toggle('justify-start-l')
  navEl.classList.toggle('items-center')
  navEl.classList.toggle('items-start-l')
  navEl.classList.toggle('flex-column')
  html.classList.toggle('has-nav-overlay')
}

const openNav = () => {
  navToggleClose.classList.toggle('pointer-events-none')
  toggleNavEls()
  tl.to(navEl, 0.4, { opacity: 1, ease: Power4.easeOut })
  .staggerFromTo(navItem, 0.4,
    { x: '-40px', opacity: 0, ease: Power4.easeOut },
    { x: 0, opacity: 1, ease: Power4.easeOut },
  0.1)
  if (html.classList.contains('has-nav-overlay')) {
    h.stopBodyScrolling(true)
    document.body.style.overflow = 'hidden'
  } else {
    h.stopBodyScrolling(false)
    document.body.style.overflow = 'visible'
  }
}

const closeNav = () => {
  navToggleClose.classList.toggle('pointer-events-none')
  tl.to(navItem, 0.4, { x: '-40px', opacity: 0, ease: Power4.easeOut })
  .to(navEl, 0.4, { opacity: 0, ease: Power4.easeOut })
  .call(() => {
    toggleNavEls()

    if (html.classList.contains('has-nav-overlay')) {
      h.stopBodyScrolling(true)
      document.body.style.overflow = 'hidden'
    } else {
      h.stopBodyScrolling(false)
      document.body.style.overflow = 'visible'
    }
  }, null, null, null)
}

const nav = () => {
  navToggle.addEventListener('click', openNav)
  navToggleClose.addEventListener('click', closeNav)
}

export default nav
