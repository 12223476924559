import jump from 'jump.js'

const els = document.querySelectorAll('.js-jump')

const jumpLinks = function() {
  if (!els) return;

  [].forEach.call(els, (link) => {
    const target = link.getAttribute('data-target')
    link.addEventListener('click', (e) => {
      e.preventDefault()
      jump(target)
    })
  })
}

export default jumpLinks
