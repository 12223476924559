import Headroom from '../../../../node_modules/headroom.js/dist/headroom'

const el = document.querySelector('.js-sidebar')

const hideSidebar = () => {
  if (!el) return

  // Headroom.js
  const hideEl = document.querySelector('.js-sidebar-hide')
  if (hideEl) {
    // construct an instance of Headroom, passing the element
    const headroom = new Headroom(hideEl)
    // initialise
    headroom.init()
  }
}

export default hideSidebar
